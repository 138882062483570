<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <div :hidden="true">
      {{ updateKey }}
    </div>
    <SumHeaderTable
      :value="campaigns"
      :global-filter-fields="['campaignName']"
      :columns="columns"
      :sums="total"
      table-prefix="general-v1"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { markRaw, defineAsyncComponent } from 'vue';
import A2CAClient from '../../../api/a2caAuthorizedClient';
import { mergeApiCampaignsWithReports, calculateMetricsGroupKPIs } from '../../../utils/reports/campaignReportUtils';
import generateCommonColumns from '../../../utils/tableUtils';
import SumHeaderTable from '../../SumHeaderTable.vue';
import ColumnHeader from './campaign/ColumnHeader.vue';

export default {
  components: { SumHeaderTable },
  inject: ['query'],
  props: {
    reports: {
      type: Object,
      required: true,
    },
    selectedProfile: {
      type: Object,
      required: true,
    },
    apiCampaigns: {
      type: Array,
      required: true,
    },
    total: {
      type: Object, required: true,
    },
  },
  data() {
    const columns = [
      {
        id: 'campaignName',
        field: 'campaignName',
        header: 'Name',
        sortable: true,
        numeric: false,
        visible: true,
        alwaysVisible: true,
        notInFilter: true,
        decimal: false,
        width: '300px',
        content: {
          center: false,
          to: null,
          badge: null,
          format: null,
          slot: {
            component: markRaw(defineAsyncComponent(() => import('./campaign/CampaignStateWarning.vue'))),
            props: (data) => ({
              profileMetricsSetted: data.profileMetricsSetted,
              sales: data.sales14d,
              kenpRoyalties: data.kindleEditionNormalizedPagesRoyalties14d,
              campaignState: data.campaignStatus,
              campaignId: data.campaignId,
              campaignName: data.campaignName,
            }),
          },
        },
      },
      {
        id: 'campaignStatus',
        field: 'campaignStatus',
        header: 'Status',
        sortable: true,
        numeric: false,
        visible: true,
        alwaysVisible: true,
        notInFilter: true,
        decimal: false,
        width: '100px',
        content: {
          center: false,
          to: null,
          badge: true,
          format: null,
        },
      },
      {
        id: 'metric',
        title: 'Metric Group',
        header: <ColumnHeader name="Metric Group" toolTip="Metric groups are used to calculate your earnings and depend on the books advertised in each campaign. You can create one metric group for all campaigns if you’re promoting a single book or series, or use different metric groups for multiple books or series." />,
        numeric: false,
        visible: true,
        notInFilter: false,
        decimal: false,
        width: '300px',
        content: {
          center: false,
          to: null,
          badge: null,
          format: null,
          slot: {
            component: markRaw(defineAsyncComponent(() => import('./campaign/CampaignMetricsDropdown.vue'))),
            props: (data) => ({
              campaignId: data.campaignId,
              metric: data.metric,
              metrics: this.metricGroups,
              appliedToAll: this.appliedToAll,
              onUpdate: async (oldMetrics, newMetrics) => this.updateMetricWithCampaign(data.campaignId, oldMetrics, newMetrics),
            }),
          },
        },
      },
      {
        id: 'rule',
        title: 'Rules',
        header: <ColumnHeader name="Rules" toolTip="The bidding rules modify the bids at a target level using the last 60 days of data. You can create your custom rules in the Bidding Rules section, or use our ‘Quick rule setup’ if you are unsure of what rules to create." />,
        numeric: false,
        visible: true,
        notInFilter: false,
        decimal: false,
        width: '300px',
        content: {
          center: false,
          to: null,
          badge: null,
          format: null,
          slot: {
            component: markRaw(defineAsyncComponent(() => import('./campaign/RuleDropdown.vue'))),
            props: (data) => ({
              rules: data.rules,
              allRules: this.rules,
              onUpdate: (newRules, oldRules) => this.updateRulesWithCampaign(data.campaignId, newRules, oldRules),
            }),
          },
        },
      },
      ...generateCommonColumns(() => this.$store.state.auth.selectedProfile),
    ].map((column, index) => ({ ...column, order: index }));

    return {
      loading: false,
      updateKey: 0,
      campaigns: null,
      filteredCampaigns: null,
      columns,
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
  async created() {
    this.loading = true;
    const client = new A2CAClient(this.query);

    const mergedCampaigns = mergeApiCampaignsWithReports(this.reports, this.apiCampaigns);

    this.campaigns = mergedCampaigns;

    if (this.selectedProfile) {
      this.metricGroups = await client.getProfileMetrics(this.selectedProfile.profileId);
      this.rules = (await client.getBidRules(this.selectedProfile.profileId)).bidRules;

      const firstAppliedToAll = this.metricGroups.find((metric) => metric.allCampaignsSelected);
      this.appliedToAll = !!firstAppliedToAll;

      const campaignToMetric = this.metricGroups.reduce((acc, next) => {
        const obj = Object.fromEntries(next.campaignIds.map((id) => [id, next]));

        return { ...obj, ...acc };
      }, {});
      const campaignToRules = this.rules.reduce((acc, next) => {
        next.campaignTargets.forEach((campaignId) => {
          if (!acc[campaignId]) {
            acc[campaignId] = [];
          }

          acc[campaignId].push(next);
        });

        return acc;
      }, {});

      const rulesAppliedToAllCampaigns = this.rules.filter((rule) => rule.allCampaignsSelected);

      this.campaigns = this.campaigns.map((campaign) => ({ ...campaign, metric: firstAppliedToAll || campaignToMetric[campaign.campaignId] }));
      this.campaigns = this.campaigns.map((campaign) => ({ ...campaign, rules: campaignToRules[campaign.campaignId] }));
      this.campaigns.forEach((campaign) => {
        const { rules = [] } = campaign;

        const merged = [...rulesAppliedToAllCampaigns, ...rules];

        const noDuplicates = merged.reduce((acc, next) => {
          acc.set(next.id, next);

          return acc;
        }, new Map());

        // eslint-disable-next-line
        campaign.rules = Array.from(noDuplicates.values());
      });
    }

    this.filteredCampaigns = this.campaigns.slice(0, 10);

    this.loading = false;
  },
  methods: {
    async updateRulesWithCampaign(campaignId, oldRules, newRules) {
      const rulesToRemove = oldRules?.filter((oldRule) => !newRules?.some((newRule) => newRule.id === oldRule.id));

      // Find rules that exist in newRules but not in oldRules (to be added)
      const rulesToAdd = newRules?.filter((newRule) => !oldRules?.some((oldRule) => oldRule.id === newRule.id));

      const allPatches = [];

      if (rulesToAdd?.length) {
        const patches = await this.updateRules(campaignId, rulesToAdd, true);
        allPatches.push(...patches);
      }
      if (rulesToRemove?.length) {
        const patches = await this.updateRules(campaignId, rulesToRemove, false);
        allPatches.push(...patches);
      }

      const client = new A2CAClient(this.query);

      client.patchBidRules(allPatches);

      const campaignToUpdate = this.campaigns.find((c) => c.campaignId === campaignId);
      campaignToUpdate.rules = newRules;
    },
    async updateMetricWithCampaign(campaignId, oldMetric, newMetric) {
      if (newMetric) {
        const newMetricToUpdate = await this.updateMetric(campaignId, newMetric, true);
        this.updateCampaignsOnMetric(newMetricToUpdate);
      }
      if (oldMetric) {
        const oldMetricToUpdate = await this.updateMetric(campaignId, oldMetric, false);
        this.updateCampaignsOnMetric(oldMetricToUpdate);
      }

      const campaignChanged = this.campaigns.find((campaign) => campaign.campaignId === campaignId);

      campaignChanged.profileMetricsSetted = !!newMetric;
      campaignChanged.metric = newMetric;

      const [borrows, costPerBorrow, costPerUnit, trueAcos, adjustedRevenue, salesRoyalties] = calculateMetricsGroupKPIs(campaignChanged, newMetric);

      campaignChanged.borrows = Math.round(borrows);
      campaignChanged.costPerBorrow = costPerBorrow;
      campaignChanged.costPerUnit = costPerUnit;
      campaignChanged.trueAcos = trueAcos;
      campaignChanged.royalties = salesRoyalties;
      campaignChanged.adjustedRevenue = adjustedRevenue;
    },

    updateRules(campaignId, rules, toAdd) {
      const rulesToUpdate = rules.map((rule) => {
        const campaignTargets = new Set([...rule.campaignTargets, campaignId]);

        if (!toAdd) campaignTargets.delete(campaignId);

        return { ...rule, campaignTargets: Array.from(campaignTargets) };
      });

      const patches = rulesToUpdate.map((rule) => ({ id: rule.id, updates: { campaignTargets: rule.campaignTargets } }));

      const rulesMap = this.rules.reduce((acc, rule) => {
        acc[rule.id] = rule;
        return acc;
      }, {});

      patches.forEach((patch) => { // update all rules objects with new campaigns ids
        const rule = rulesMap[patch.id];
        rule.campaignTargets = patch.updates.campaignTargets;
      });

      return patches;
    },

    updateMetric(campaignId, metric, toAdd) {
      let campaignIds;

      if (toAdd) {
        campaignIds = new Set([...metric.campaignIds, campaignId]);
      } else {
        campaignIds = new Set(metric.campaignIds);
        campaignIds.delete(campaignId);
      }

      const metricToUpdate = { ...metric, campaignIds: Array.from(campaignIds) };

      const client = new A2CAClient(this.query);

      return client.updateMetric(
        this.selectedProfile.profileId,
        metricToUpdate,
      ).then(() => metricToUpdate);
    },
    updateCampaignsOnMetric(metric) {
      const metricGroup = this.metricGroups.find((m) => m.id === metric.id);
      metricGroup.campaignIds = metric.campaignIds;
    },

  },
};
</script>
